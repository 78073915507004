/**
 * 导出规范：
 *      router/model的模块引入，应该都导出一下三个字段
 *         - Components 模块import
 *         - staticRoutes  要添加的静态路由
 *         - staticRoutes[]['parent']  此路由要插入的父路由，默认在app.router下
 */

let tnModules = {}
// 内网的包为了安全起见，上线把所有的模块都打进去
if (process.env.VUE_APP_ENV === 'PRIVATE') {
    // 天安的modules
    tnModules = require('../tianan/index')['Components'] || {}
}


// 引入模块
const Components = {
    ...tnModules,

    // 全息画像
    "complete-portrait": () => import(/* webpackChunkName: "complete-portrait" */ "@/pages/complete_portrait/index"),
    // 实时检索
    "retrieval-realtime": () => import(/* webpackChunkName: "retrieval-realtime" */ "@/pages/realtime_retrieval/index"),
    // 多元化检索 -- 首页展示在天迹目录下
    "analysis-sms": () => import(/* webpackChunkName: "analysis-sms" */ "@/pages/analysis/sms"),
    // 消费行为分析
    // "analysis-payments": () => import(/* webpackChunkName: "analysis-payments" */ "@/pages/analysis/payments/indexs/payments"),
    "analysis-payments": () => import(/* webpackChunkName: "analysis-payments" */ "@/pages/analysis_expense/index"),
    // 购物行为分析 -- 天安 -> 天迹, 天迹
    "analysis-shopp": () => import(/* webpackChunkName: "analysis-shopp" */ "@/pages/analysis/shopp/shopp"),
    // 地址反查
    "analysis-addr": () => import(/* webpackChunkName: "address_query" */ "@/pages/address_query/index"),
    // 关系人图谱分析
    "contact-match": () => import(/* webpackChunkName: "contact-match" */ "@/pages/contact/match"),
    // 延时检索
    "retrieval-delay": () => import(/* webpackChunkName: "retrieval-delay" */ "@/pages/realtime_retrieval/delay/index"),
    // IP碰撞
    "loc-ip": () => import(/* webpackChunkName: "loc-ip" */ "@/pages/loc/ip"),
    // 监控预警
    "monitor-warn": () => import(/* webpackChunkName: "monitor-warn" */ "@/pages/monitor/warn"),
    // 资金分析
    "fund-download": () => import(/* webpackChunkName: "fund-download" */ "@/pages/fund"),
    // 信息修复
    "info-repair": () => import(/* webpackChunkName: "info-repair" */ "@/pages/extlinks/inforepair"),
    // 人流监控
    "monitor-pstream": () => import(/* webpackChunkName: "monitor-pstream" */ "@/pages/people_stream/pstream"),
    // 查询工具
    //  - mac地址分析
    "mac-match": () => import(/* webpackChunkName: "mac-info" */ "@/pages/mac/info"),
    //  - 身份证归属地查询
    "cert-match": () => import(/* webpackChunkName: "location-identity" */ "@/pages/location_query/identity"),
    //  - 手机号归属地查询
    "notel-match": () => import(/* webpackChunkName: "location-phone" */ "@/pages/location_query/phone"),
    //  - 银行卡归属地查询
    "card-match": () => import(/* webpackChunkName: "location-bankCard" */ "@/pages/location_query/bankCard"),
    //  - whois查询
    "whois-match": () => import(/* webpackChunkName: "location-whois" */ "@/pages/location_query/whois"),
    //  - company查询
    "company-match": () => import(/* webpackChunkName: "location-company" */ "@/pages/location_query/company"),
    //  - 用户界面
    "opinioncrawl-user": () => import(/* webpackChunkName: "opinioncrawl-user" */ "@/pages/opinion_crawl/user"),
    // - tg
    "tg-query": () => import(/* webpackChunkName: "tg-query" */ "@/pages/tg/query"),

    // 人员定位
    "person-positioning": () => import(/* webpackChunkName: "person-positioning" */ "@/pages/person_position/index.vue"),

    // 货运物流
    // 货车轨迹
    "transport-pathmap": () => import(/* webpackChunkName: "transport_pathmap" */ "@/pages/transport/pathmap/index.vue"),
    "transport-pathtrack": () => import(/* webpackChunkName: "transport_pathtrack" */ "@/pages/transport/pathtrack/index"),
    "transport-area-monitor": () => import(/* webpackChunkName: "transport-area-monitor" */ "@/pages/transport/monitor/index"),
    "transport-vehicle-info": () => import(/* webpackChunkName: "transport-vehicle-info" */ "@/pages/transport/info/index"),

    // 哈啰/滴滴线索查询
    "travel-data": () => import(/* webpackChunkName: "travel_data" */ "@/pages/on_chain/index"),
    // 寄递信息查询
    "waybill": () => import(/* webpackChunkName: "waybill" */ "@/pages/jd/index"),
    
    //  - wifi分析
    "wifi-analysis": () => import(/* webpackChunkName: "wifi-analysis" */ "@/pages/wifi_analy/user/index"),
    //  - app分析
    "app-analysis": () => import(/* webpackChunkName: "app-analysis" */ "@/pages/app_analy/user/index"),
    // ========设备查询=============
     //  - wifi分析
     "device-wifi": () => import(/* webpackChunkName: "device-wifi" */ "@/pages/device/wifi/index"),
     //  - app分析
     "device-app": () => import(/* webpackChunkName: "device-app" */ "@/pages/device/app/index"),

    // 虚拟身份查询
    "virtual-identity": () => import(/* webpackChunkName: "virtual_identity" */ "@/pages/virtual_identity/index"),

    // 平台管理
    //  - 账号信息
    "platform-manager": () => import(/* webpackChunkName: "platform-manager" */ "@/pages/platform/manager"),
    // - 多元化检索任务
    "task-sms-analysis": () => import(/* webpackChunkName: "task-sms-analysis" */ "@/pages/log/taskAnalysis"),
    // - 通知中心
    "platform-notice-center":  () => import(/* webpackChunkName: "platform-notice-center" */ "@/pages/log/noticeCenter"),
    "platform-data-confirm":() => import(/* webpackChunkName: "platform-data-confirm" */ "@/pages/platform/confirm_data/index"),
    // - 日志统计
    "log-istat": () => import(/* webpackChunkName: "log-istat" */ "@/pages/log/istat"),
    // - 白名单
    "white-list":  () => import(/* webpackChunkName: "white-list" */ "@/pages/white_list"),
    // // - app装机判断管理
    // "app-install-judgment-admin": () => import(/* webpackChunkName: "app-install-query-admin" */ "@/pages/app_analy/admin/installed_judgment"),
    // - 客户列表
    "agent_customer": () => import(/* webpackChunkName: "agent-customer" */ "@/pages/agent_dealer/customer/index"),
    // - 银商子账号权限管理
    "platform-child-ys-auth": () => import(/* webpackChunkName: "platform-child-ys-auth" */ "@/pages/platform/ys_child_auth/index"),

    // 汇总的日志查询
    "log-search": () => import(/* webpackChunkName: "log-audit" */ "@/pages/log/muster"),
    // 技术支持
    "customer-service": () => import(/* webpackChunkName: 'customer_service' */ '@/pages/util_view/customer_service'),
    // 多元化检索主页
    "sms_main":()=>import(/* webpackChunkName: 'sms-main' */ '@/pages/sms_main/index'),
    // 风险人员库
    "risk_people_pool": () => import(/* webpackChunkName: 'risk_people_pool' */ '@/pages/risk_people_pool/pool'),
    // 风险人员-收藏夹
    "risk_people_favorite": () => import(/* webpackChunkName: 'risk_people_favorite' */ '@/pages/risk_people_pool/favorite'),
    // 轨迹分析（智巡）
    "zhixun-menu": () => import(/* webpackChunkName: 'zhixun_menu' */ '@/pages/zhixun/index'),
    // 重点人员监控预警（开发中）
    "person-monitoring":() => import(/* webpackChunkName: "person-monitoring" */ "@/pages/person_monitor/index.vue"),
    // 停车记录查询
    "parking-record":() => import(/* webpackChunkName: "parking-record" */ "@/pages/parking_record/index.vue"),
    
}


// 做 name和 link 不匹配的做兼容，（不匹配无法做keep-alive缓存）
const compatibleList = {
    'mac-info': 'mac-match',    // MAC地址分析
    'platform-publish': 'platform-notice-publish',  // 发布平台通知
}
Object.keys(compatibleList).forEach(item => Components[item] = Components[compatibleList[item]])


/**
 * 不要权限控制的静态路由, 写在此处可访问
 */
const staticRoutes = [
    {
        path: '/tm/index',
        name: 'tm_index',
        parent: '',
        component: () => import(/* webpackChunkName: 'tm_index' */ '@/pages/index/tm/index')
    }
]



// 导出
export {
    Components,
    staticRoutes,
}